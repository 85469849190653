.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 800px;
  min-height: 100vh;
  padding: var(--gutter-base);
  text-align: center;

  h1 {
    margin: var(--gutter-base) 0;
  }

  p {
    font-family: Courier, monospace;
    margin-bottom: var(--gutter-base);
  }
}
