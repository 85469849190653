.sidebar {
  background: var(--color-dark);
  border-radius: 0 20px 20px 0;
  bottom: 0;
  box-shadow: 1px 1px 4px rgba(var(--color-black), 0.2);
  color: var(--color-white);
  display: inline-block;
  overflow-y: auto;
  padding: 20px;
  position: fixed;
  top: 0;
  width: var(--sidebar-width);
  z-index: var(--z-index-1);
}

.sidebarMobile {
  border-radius: 0;
  left: 0;
  top: 80px;
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.sidebarMobileVisible {
  transform: translateX(0);
}

.sidebarMenu {
  color: var(--color-gray-5);
  font-size: 14px;
  font-weight: bold;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 100px;
  padding-left: 0;

  i {
    display: inline-block;
    margin-right: 5px;
  }

  li + li {
    margin-top: 5px;
  }

  :global .nav-link {
    color: var(--color-gray-5);
    padding: 10px;
  }
}

.sidebarMenuMobile {
  margin-top: 50px;
}
