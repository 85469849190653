:root {
  --color-primary: #3f83d4;
  --color-white: #fff;
  --color-black: #000;
  --color-dark: #212121;
  --color-red: #f00;
  --color-green: #b2ff35;

  // gray
  --color-gray-5: #f2f2f2;
  --color-gray-10: #e6e6e6;
  --color-gray-20: #ccc;
  --color-gray-30: #b3b3b3;
  --color-gray-40: #999;
  --color-gray-50: #808080;
  --color-gray-60: #666;
  --color-gray-70: #4d4d4d;
  --color-gray-80: #333;
  --color-gray-90: #1a1a1a;

  // colors-other
  --color-text-title: #505050;
  --color-stars-feedback: #fbb042;
  --color-bg-headers: #f1f1f1;

  // font-size
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-1: 28px;
  --font-size-2: 25px;
  --font-size-3: 22px;
  --font-size-4: 20px;
  --font-size-5: 18px;
  --font-size-6: 16px;

  // gutter
  --gutter-xs: 4px;
  --gutter-sm: 8px;
  --gutter-base: 16px;
  --gutter-lg: 32px;
  --gutter-xl: 64px;

  // z-index
  --z-index-1: 100;
  --z-index-2: 250;
  --z-index-3: 500;
  --z-index-4: 1500;
  --z-index-5: 2000;

  // sidebar
  --sidebar-width: 200px;
  --sidebar-margin: var(--gutter-base);
}

