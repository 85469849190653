@import 'src/styles/utils';

.wrapper {
  color: var(--color-dark);
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  padding: var(--gutter-base);

  @include xl {
    flex-wrap: nowrap;
  }

  h1 {
    color: var(--color-primary);
    margin-bottom: var(--gutter-base);
  }
}

.nameWithStatus {
  align-items: center;
  display: inline-flex;

  > div {
    margin-right: var(--gutter-sm);
  }
}

.talent {
  padding-bottom: 100px;
  width: 100%;

  @include lg {
    padding-bottom: 0;
  }

  @include xl {
    width: calc(100% - 320px);
  }
}

.client {
  margin-top: var(--gutter-base);
  width: 100%;

  @include xl {
    margin-left: var(--gutter-base);
    margin-top: 0;
    width: 320px;
  }

  form [type='button'] {
    align-items: center;
    color: var(--color-primary);
    display: inline-flex;
    justify-content: center;
    margin-top: var(--gutter-xs);
    width: 100%;
  }
}

.comment {
  h1 {
    color: var(--color-dark);
  }

  p:last-of-type {
    border-bottom: 1px solid var(--color-gray-20);
    font-size: var(--font-size-base);
    margin-bottom: var(--gutter-xs);
    padding-bottom: var(--gutter-sm);
  }

  [type='button'] {
    color: var(--color-primary);
  }
}

.contentWrapper {
  background-color: var(--color-bg-headers);
  border-radius: 16px;
  max-width: 1280px;
  padding: 0;
}

.contentBody {
  padding: var(--gutter-base);
  width: 100%;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.contentFooter {
  border-top: 1px solid #e5e5e5;
  padding: 10px 15px 15px;
  width: 100%;
}

.contentButton {
  color: var(--color-white);
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  padding: 1px 4px 1px 4px;
}

.contentTitle {
  color: var(--color-text-title);
  font-size: 14px;
  font-weight: bold;
}

.socialIcons {
  margin-right: 10px;
  width: 50px;
}

.links {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: var(--gutter-lg);
}

.ratings {
  margin-bottom: var(--gutter-base);
}

.ratingsMobile {
  background-color: var(--color-gray-5);
  bottom: 0;
  font-size: var(--font-size-base);
  left: 0;
  padding: var(--gutter-base);
  position: fixed;
  text-align: center;
  width: 100%;
}

.statusBadge {
  background-color: var(--color-black);
  font-size: var(--font-size-base);
}
