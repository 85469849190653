.wrapper {
  display: inline-block;
  position: relative;
}

.button {
  align-items: center;
  background-color: var(--color-primary);
  border: 0;
  border-radius: 6px;
  color: var(--color-white);
  display: inline-flex;
  padding: 6px 10px;
  transition: 0.5s;

  &:hover {
    background-color: var(--color-dark);
  }

  :global(.fa-link) {
    margin-left: 4px;
  }
}

.alert {
  animation: fadeOut forwards 0.25s;
  background-color: var(--color-gray-70);
  border-radius: 6px;
  color: var(--color-white);
  font-size: var(--font-size-xs);
  left: 50%;
  line-height: 1;
  padding: 4px 8px;
  position: absolute;
  transform: translateX(-50%);
  z-index: var(--z-index-1);

  &:after {
    border-color: var(--color-gray-70) transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: ' ';
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 100%;
  }
}

@keyframes fadeOut {
  0% {
    bottom: 0;
    opacity: 0;
  }

  100% {
    bottom: 130%;
    opacity: 1;
  }
}
