html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  background-color: var(--color-white);
  color: var(--color-dark);
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: DM Sans, sans-serif;
  font-weight: bold;
}

h1 {
  font-size: var(--font-size-1);
}

h2 {
  font-size: var(--font-size-2);
}

h3 {
  font-size: var(--font-size-3);
}

h4 {
  font-size: var(--font-size-4);
}

h5 {
  font-size: var(--font-size-5);
}

h6 {
  font-size: var(--font-size-6);
}

button {
  background: transparent;
  border: 0;
  color: inherit;
  display: inline-flex;
  font-size: inherit;
  line-height: 1;
  padding: 0;
}

svg {
  height: auto;
  max-width: 100%;
}

.btn {
  font-size: 16px;
}

.route-authentication {
  min-height: 100vh;
  padding-top: 120px;
  width: 100%;
}

.panelBody {
  margin-left: var(--sidebar-width) !important;
  width: calc(100% - var(--sidebar-width));
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.icon-spin {
  animation: spin 1s infinite linear;

  &-r {
    margin-left: 6px;
  }

  &-l {
    margin-right: 6px;
  }
}
