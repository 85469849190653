code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-control {
  font-size: 16px;
}

input[type='file'] {
  width: 100%;
}
