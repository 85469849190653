.wrapper {
  background-color: var(--color-gray-70);
  border: 1px solid var(--color-gray-20);
  border-radius: 50%;
  height: 16px;
  width: 16px;
}

.active {
  background-color: var(--color-green);
}

.reject {
  background-color: var(--color-red);
}
