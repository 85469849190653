@import 'src/styles/utils';

.wrapper {
  background-color: var(--color-white);
  border-radius: var(--gutter-base);
  margin: 0 auto;
  max-width: 420px;
  padding: 50px 60px;
  text-align: center;
  width: 100%;
}

.logo {
  margin-bottom: var(--gutter-lg);
}

.title {
  color: var(--color-text-title);
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 0;
}

.description {
  font-size: 14px;
  margin-top: var(--gutter-sm);
}

.form {
  margin-top: var(--gutter-base);
  padding: 0 var(--gutter-base);

  a {
    display: block;
    font-size: 12px;
    margin-bottom: 15px;
  }

  :global(.form-group) {
    &:last-of-type {
      margin-bottom: 0;
      margin-top: var(--gutter-base);
    }
  }

  :global(.form-control) {
    background: var(--color-gray-5);
    border: 0;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
  }

  :global(.btn-primary) {
    border: 0;
    border-radius: 6px;
    font-size: 16px;
    line-height: 1;
    padding: 10px;
  }
}

.error {
  color: var(--color-red);
  margin-top: var(--gutter-base);
}

.footer {
  font-size: 14px;
  margin-top: var(--gutter-base);

  a {
    color: var(--color-gray-40);
  }
}
