.wrapper {
  color: var(--color-dark);
  margin-bottom: var(--gutter-base);
  margin-top: 0;
  padding-left: var(--gutter-sm);

  h1 {
    font-size: var(--font-size-1);
    margin-bottom: 0;
  }

  h2 {
    font-size: var(--font-size-3);
    margin-bottom: 0;
  }
}

.wrapperSecondary {
  color: var(--color-gray-70);
}

.wrapperWithBorder {
  border-bottom: 1px solid var(--color-gray-20);
  padding-bottom: var(--gutter-base);
}

.children {
  margin-top: var(--gutter-sm);
}
