@import 'src/styles/utils';

.wrapper {
  width: 100%;
}

.item {
  background: var(--color-bg-headers);
  border-radius: 16px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  color: var(--color-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--gutter-base);
  padding-bottom: 0;
  position: relative;
  transition: 0.2s;

  &:before {
    border-radius: 16px;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.2s;
  }

  &:hover {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.15);
    text-decoration: none;

    &:before {
      border-left: 4px solid var(--color-primary);
    }

    .itemHeading {
      color: var(--color-primary);
    }

    .itemColumns {
      color: var(--color-dark);
    }
  }

  &:not(:first-of-type) {
    margin-top: var(--gutter-base);
  }
}

.itemHeading {
  margin-bottom: var(--gutter-base);

  > * {
    margin-bottom: 0;
  }
}

.itemColumns {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  @include md {
    align-items: center;
  }

  h4 {
    color: var(--color-text-title);
    font-size: var(--font-size-sm);
    line-height: 1;
  }

  > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--gutter-base);
    margin-right: var(--gutter-base);
    max-width: 110px;
    min-width: 110px;

    @include xsm {
      max-width: none;
      min-width: 120px;
    }

    @include md {
      margin-right: var(--gutter-lg);
    }

    &:last-of-type {
      margin-right: 0;
    }

    &[data-type='indicator'] {
      margin-right: var(--gutter-sm);
      min-width: 16px;
    }

    &[data-type='company'] {
      min-width: auto;
    }
  }
}
