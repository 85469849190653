@import 'src/styles/utils';

.credits {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gutter-base);
  justify-content: center;
}


.credit {
  align-content: center;
  background-color: var(--color-gray-5);
  border: 1px solid var(--color-gray-5);
  border-radius: 12px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  color: var(--color-text-title);
  display: grid;
  font-weight: bold;
  max-width: 85px;
  height: 100px;
  padding: 10px;
  text-align: center;

  @include xsm {
    height: auto;
    max-width: 100px;
  }

  @include md {
    flex: 1;
    max-width: none;
  }

  span {
    display: block;
    font-size: 12px;
    min-height: 28px;
    line-height: 1.2;
    margin-bottom: var(--gutter-sm);
  }

  p {
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
  }
}

.creditInProgress p {
  color: var(--color-primary);
}

.creditSetup p {
  color: var(--color-stars-feedback);
}

.creditAvailable p {
  color: var(--color-primary);
}

.titleAndIndicator {
  align-items: center;
  display: flex;

  div:first-of-type {
    margin-right: 10px;
  }
}
