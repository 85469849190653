.applicationHeading {
  align-items: center;
  display: flex;

  h3 {
    line-height: 1;
    margin-bottom: 0;
    margin-left: var(--gutter-sm);
  }
}

.cycle {
  margin-bottom: var(--gutter-base);
  width: 100%;

  h2 {
    font-size: var(--font-size-3);
    margin-bottom: var(--gutter-sm);
  }

  > div {
    display: flex;

    > div {
      &:not(:first-of-type) {
        margin-left: var(--gutter-base);
      }
    }
  }
}
