@keyframes portalHide {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes portalShow {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.wrapper {
  align-items: center;
  animation-direction: reverse;
  animation-duration: 0.5s;
  animation-name: portalHide;
  animation-play-state: paused;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-3);
}

.wrapperActive {
  animation-direction: normal;
  animation-name: portalShow;
  animation-play-state: running;

  .overlay {
    opacity: 1;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.5s;
}

.content {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: auto;
  z-index: var(--z-index-1);
}

.closeButton {
  color: var(--color-black);
  font-size: 20px;
  height: 30px;
  line-height: 1;
  pointer-events: all;
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  z-index: var(--z-index-2);
}
