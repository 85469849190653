.wrapper {
  width: 100%;
}

.wrapperPublic {
  align-items: center;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  min-height: 100vh;
}
