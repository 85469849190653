@import 'src/styles/utils';

.breadcrumbs {
  ul {
    color: var(--color-gray-60);
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: bold;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-bottom: 3px;
      padding-left: 3px;
      padding-right: 3px;

      &.active {
        border-bottom: 4px solid var(--color-primary);

        a {
          color: var(--color-gray-60);
        }
      }

      &:not(.active) {
        a {
          color: var(--color-gray-40);
        }
      }

      &:not(:last-child):after {
        content: icon(f105);
        font-family: 'Font Awesome 5 Free', sans-serif;
        font-weight: 900;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}
