.wrapper {
  border-bottom: 1px solid var(--color-gray-20);
  margin-bottom: var(--gutter-sm);
  margin-left: calc(var(--sidebar-width) + var(--sidebar-margin)) !important;
  padding-top: var(--gutter-base);
  width: calc(100% - (var(--sidebar-width) + var(--sidebar-margin)));
}

.wrapperMobile {
  align-items: center;
  background-color: var(--color-dark);
  border-bottom: 1px solid var(--color-dark);
  display: flex;
  height: 80px;
  left: 0;
  padding-left: var(--gutter-base);
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-index-5);
}

.welcome {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  margin-left: auto;
  padding-right: var(--gutter-base);
  text-align: right;
}

.logo {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 78px;
  padding: var(--gutter-base);

  img {
    max-width: 100px;
    max-height: 45px;
  }
}

.header {
  align-items: stretch;
  display: flex;
  margin-bottom: var(--gutter-base);
  min-height: 70px;

  p {
    margin-bottom: 0;

    &:first-of-type {
      color: var(--color-dark);
      font-size: 18px;
    }

    &:last-of-type {
      color: var(--color-gray-60);
      font-size: 14px;
    }
  }
}

.menu {
  color: #fff;
  width: 16px;
  flex-shrink: 0;

  svg {
    width: 16px;
  }
}

.brand {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding-left: var(--gutter-sm);
  padding-right: var(--gutter-base);

  img {
    max-height: 60px;
    max-width: 140px;
  }
}
