.wrapper {
  margin: 0 auto;
  max-width: 480px;
  padding: var(--gutter-lg);
  text-align: center;
}

.icon {
  margin-bottom: 8px;
}

.title {
  line-height: 1.2;
  margin-bottom: var(--gutter-sm);
}

.content {
  margin-top: var(--gutter-base);
}
