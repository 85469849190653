.wrapper {
  align-items: center;
  display: inline-flex;
  white-space: nowrap;

  span {
    display: inline-flex;
    line-height: 1;

    &:last-of-type {
      cursor: pointer;
      margin-left: 3px;
    }
  }
}
