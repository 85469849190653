@keyframes loaderAnimation {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loaderAnimation;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 1000px 104px;
  border-radius: 10px;
  display: inline-block;
  overflow: hidden;
  position: relative;
}
